import { ProjectHeaderWidgetComponentIds } from '../../projectHeaderWidget/config/constants';

export enum ProjectPageWidgetComponentIds {
  Root = '#projectPageWidgetRoot',
  ProjectWidget1 = '#projectWidget1',
  EmptyStateWidget = '#projectEmptyStateWidget1',
}

export enum ProjectPageWidgetEvents {}

export const ProjectPageWidgetTabsArray: widgetDesignTabsBuilderArray = [
  {
    label: 'Project_Design_Panel_Title',
    roles: [ProjectHeaderWidgetComponentIds.Title],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Details_Label',
    roles: ['#detailLabel'],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Details_Text',
    roles: ['#detailText'],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Description',
    roles: [ProjectHeaderWidgetComponentIds.Description],
    skipOnEditorX: true,
  },
  {
    label: 'Project_Design_Panel_Hero_Image',
    roles: [ProjectHeaderWidgetComponentIds.GalleyCover],
  },
  {
    label: 'Project_Design_Panel_Page_Background',
    roles: [ProjectPageWidgetComponentIds.Root],
  },
  {
    label: 'Project_Design_Panel_Navigation_Buttons',
    roles: ['#navigationButton'],
    skipOnEditorX: true,
  },
  // {
  //   label: 'Project_Design_Panel_Gallery',

  //   roles: [ProjectWidgetComponentIds.Gallery],
  // },
];

export const ProjectPageWidgetPresets: WidgetPresetsArray = [
  {
    // 1.2
    id: 'externalPreset-variants-l9v9bw1g',
    connectedMobilePresetID: 'variants-l71pcd2c',
    src: 'https://static.wixstatic.com/media/8bb438_3f623a69f8674a43ad89c4dbe1245f2b~mv2.png',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 2.1
    id: 'externalPreset-variants-l71toaf3',
    connectedMobilePresetID: 'variants-l71tr32a',
    src: 'https://static.wixstatic.com/media/8bb438_fec081479be94dc590d82ee3356a9d09~mv2.png',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 3.1
    id: 'externalPreset-variants-l74qktjj',
    connectedMobilePresetID: 'variants-l71s8wjq',
    src: 'https://static.wixstatic.com/media/8bb438_7947e750f8ce4ea8a5bb07f4d124f0fe~mv2.png',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 4.1
    id: 'externalPreset-variants-l71uhe49',
    connectedMobilePresetID: 'variants-l71vneg0',
    src: 'https://static.wixstatic.com/media/8bb438_f84a96bbc36846dba8490d6396899fe6~mv2.png',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 5.1
    id: 'externalPreset-variants-l71x7bcn',
    connectedMobilePresetID: 'variants-l724u4q0',
    src: 'https://static.wixstatic.com/media/8bb438_5a6e956a47184b348184f6bfd45399c8~mv2.png',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 6.1
    id: 'externalPreset-variants-l728gqx9',
    connectedMobilePresetID: 'variants-l728le0g',
    src: 'https://static.wixstatic.com/media/8bb438_6e7af2848c854a8d8ae812c089ab17ca~mv2.png',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 7.1
    id: 'externalPreset-variants-l72a759d',
    connectedMobilePresetID: 'variants-l72a9j26',
    src: 'https://static.wixstatic.com/media/8bb438_47a5adcec3634c13a3b5f65d5ee04d66~mv2.png',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
  {
    // 8.1
    id: 'externalPreset-variants-l7366shq',
    connectedMobilePresetID: 'variants-l73bsbwq',
    src: 'https://static.wixstatic.com/media/8bb438_88c1b4db9eee41e5b6963e41b9b62411~mv2.png',
    layout: {
      width: '114px',
      height: '154px',
    },
  },
];
